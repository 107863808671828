export const logo = require("./logo.webp");
export const logoBig = require("./logo-big.webp");
export const logoSalt = require("./logo-salt.webp");
export const iconInstagram = require("./icon-instagram.webp");
export const iconTwitter = require("./icon-twitter.webp");
export const iconFacebook = require("./icon-facebook.webp");
export const iconYoutube = require("./icon-youtube.webp");
export const iconLinkedin = require("./icon-linkedin.webp");
export const iconPartnerUnesco = require("./icon-partner-unesco.webp");
export const iconPartnerBreakFree = require("./icon-partner-break-free-from-plastic.webp");
export const iconPartnerUNHabitat = require("./icon-partner-unhabitat.webp");
export const iconPartnerUNV = require("./icon-partner-unv.webp");
export const iconProgressWorld = require("./icon-progress-world.webp");
export const iconProgressTrash = require("./icon-progress-trash.webp");
export const iconProgressHand = require("./icon-progress-hand.webp");
export const bgIndonesiaBersih = require("./bg-indonesia-bersih.webp");
export const iconMenuHamburger = require("./icon-menu-hamburger.webp");
export const bgIndonesiaBebasSampah = require("./bg-indonesia-bebas-sampah.webp");
export const aboutWcd = require("./about-wcd.webp");
export const aboutWcdDesk = require("./aboutUsDesktop.webp");
export const bgRoundsColor = require("./bg-rounds-color.webp");
export const iconsEWallet = require("./icons-ewallet.webp");
